app.controller('LancamentoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Lancamento', 'Conta', 'CentroCusto', 'Banco', 'FormaPagamento', 'Pessoa', 'Contrato', function($scope, $state, $stateParams, toastr, Lancamento, Conta, CentroCusto, Banco, FormaPagamento, Pessoa, Contrato) {
	var vm = this;
	
	vm.lancamentoId = $stateParams.lancamentoId;
    	//Se tem data de pagamento o status é pago e desabilitado para edição
    	vm.statusReadOnly = false;
    	//Se o status é pago a data de pagamento é obrigatória
    	vm.hasPaid = false;

	getBancos();
	getContas();
	getCentrosCusto();
	getFormasPagamento();
	getLancamento();

	function getLancamento() {
		Lancamento.getLancamento(vm.lancamentoId)
			.success(function(response) {
				vm.lancamento = response;
                statusReadOnly((vm.lancamento.data_pagamento ? vm.lancamento.data_pagamento : ''), '');
				dtPagamentoRequired();
			});
	}

	function getContas(){
		Conta.getContas()
			.then(function(response) {
				vm.contas = response.data;
			});
	}

	function getBancos(){
		Banco.getBancos()
			.then(function(response) {
				vm.bancos = response.data;
			});
	}

	function getCentrosCusto(){
		CentroCusto.getCentrosCusto()
			.then(function(response) {
				vm.centros_custo = response.data;
			});
	}

	function getFormasPagamento(){
		FormaPagamento.getFormasPagamento()
			.then(function(response) {
				vm.formas_pagamento = response.data;
			});
	}

	vm.popupDataEmissao = {
		opened: false
	};

	vm.popupDataVencimento = {
		opened: false
	};

	vm.popupDataPagamento = {
		opened: false
	};

	$scope.openDataEmissao = function() {
		vm.popupDataEmissao.opened = true;
	}

	$scope.openDataVencimento = function() {
		vm.popupDataVencimento.opened = true;
	}

	$scope.openDataPagamento = function() {
		vm.popupDataPagamento.opened = true;
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social,nome_fantasia', null, false, false, Pessoa.TipoCadastro.Relacionamento)
			.then(function(response) {
				vm.pessoas = response.data.items;
			});
	}

	$scope.refreshContratos = function(search) {
		Contrato.getContratos(1, search)
			.then(function(response) {
				vm.contratos = response.data.items;
			});
	}

	$scope.updateLancamento = function() {
		Lancamento.updateLancamento(vm.lancamento)
			.then(function() {
				toastr.success('Lançamento alterada com sucesso.');
				$state.go('dashboard.lancamentos.list', {
					'pessoaId': $stateParams.pessoaId,
					'searchText': $stateParams.searchText,
					'columns': $stateParams.columns,
					'sortType': $stateParams.sortType,
					'sortReverse': $stateParams.sortReverse,
					'filtros': $stateParams.filtros
				});
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.lancamentoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}

	$scope.updateValorLiquido = function(detalhe) {
		detalhe.valor_liquido = parseFloat(detalhe.valor_bruto) - parseFloat(detalhe.desconto) + parseFloat(detalhe.valor_juros);
	}

	$scope.preencherCentroCusto = function(detalhe) {
		if (detalhe.conta.centro_custo_id) {
			detalhe.centro_custo_id = detalhe.conta.centro_custo_id;
		}
	}

	$scope.editContrato = function(index) {
		vm.editingContrato = angular.copy(vm.lancamento.contratos[index], {});
		vm.editingContratoIndex = index;
	}

	$scope.saveContrato = function() {
		if (vm.editingContratoIndex >= 0) {
			vm.lancamento.contratos[vm.editingContratoIndex] = vm.editingContrato;
			vm.editingContratoIndex = null;
		} else {
			vm.lancamento.contratos.push(vm.editingContrato);
		}
		vm.editingContrato = {};
		vm.contratoForm.$setPristine();
	}

	$scope.deleteContrato = function(index) {
		if (vm.lancamento.contratos[index].id) {
			vm.lancamento.contratos[index]._destroy = true;
		} else {
			vm.lancamento.contratos.splice(index, 1);
		}
	}

	$scope.somaContratos = function() {
		if (!vm.lancamento) {
			return 0;
		}

		var soma = 0;
		angular.forEach(vm.lancamento.contratos, function(contrato) {
			if (!contrato._destroy) {
				soma += parseFloat(contrato.valor);
			}
		});

		return soma;
	}

	$scope.addAnexo = function() {
		vm.lancamento.anexos.push({
			file: '',
			enviando: false
		});
	}

	$scope.deleteAnexo = function(index) {
		if (vm.lancamento.anexos[index].id) {
			vm.lancamento.anexos[index]._destroy = true;
		} else {
			vm.lancamento.anexos.splice(index, 1);
		}
	}

	$scope.uploadAnexo = function(index) {
		var arquivo = vm.lancamento.anexos[index].file;
		vm.lancamento.anexos[index].enviando = true;
		Lancamento.enviarArquivo(vm.lancamento.id, arquivo)
			.then(function(response) {
				vm.lancamento.anexos[index] = response.data;
			})
			.catch(function(response) {
				toastr.error('Erro ao enviar arquivo.');
			});
	}

	$scope.addDetalhe = function() {
		vm.lancamento.detalhes.push({
			conta: {},
			valor_bruto: 0,
			desconto: 0,
			valor_juros: 0,
			valor_liquido: 0
		});
	}

	$scope.deleteDetalhe = function(index) {
		if (vm.lancamento.detalhes[index].id) {
			vm.lancamento.detalhes[index]._destroy = true;
		} else {
			vm.lancamento.detalhes.splice(index, 1);
		}
	}

	$scope.$watch(function() { return vm.lancamento; }, function(oldVal, newVal) {
		if (!vm.lancamento || !vm.lancamento.detalhes) 
			return 0;

		var valor_liquido = 0;
		var valor_bruto = 0;
		var desconto = 0;
		var valor_juros = 0;
		for (var i = 0; i < vm.lancamento.detalhes.length; i++) {
			if (!vm.lancamento.detalhes[i]._destroy) {
				valor_liquido += parseFloat(vm.lancamento.detalhes[i].valor_liquido);
				valor_bruto += parseFloat(vm.lancamento.detalhes[i].valor_bruto);
				desconto += parseFloat(vm.lancamento.detalhes[i].desconto);
				valor_juros += parseFloat(vm.lancamento.detalhes[i].valor_juros);
			}
		}

		vm.lancamento.valor_liquido = valor_liquido;
		vm.lancamento.valor_bruto = valor_bruto;
		vm.lancamento.desconto = desconto;
		vm.lancamento.valor_juros = valor_juros;
	}, true);

	$scope.preencherDataVencimento = function() {
		return false;
	}

    /**
     * Controla a ativação/desativação do campo status
     */
    $scope.statusReadOnly = function(newValue, oldValue){
        statusReadOnly((newValue ? newValue : ''), (oldValue ? oldValue : ''));
    }

    function statusReadOnly(newValue, oldValue){
        if(newValue != oldValue){
            vm.statusReadOnly = (newValue ? true : false);
            if(vm.statusReadOnly){
                vm.lancamento.status = 'E';
                dtPagamentoRequired();
            }else{
                vm.lancamento.status = 'P';
                dtPagamentoRequired();
            }
        }
    }

    $scope.dtPagamentoRequired = function(){
        dtPagamentoRequired();
    }

    function dtPagamentoRequired(){
        if(vm.lancamento.status == 'E'){
            vm.hasPaid = true;
        }else{
            vm.hasPaid = false;
        }
    }
}]);