app.controller('PessoaNewCtrl', ['$scope', '$location', 'toastr', 'Pessoa', 'Setor', 'Origem', 'RamoAtuacao', '$uibModal', 'Cep', 'TipoRelacionamento', 'TipoContato', 'Motivo', 'Produto', 'Auth', '$state', function($scope, $location, toastr, Pessoa, Setor, Origem, RamoAtuacao, $uibModal, Cep, TipoRelacionamento, TipoContato, Motivo, Produto, Auth, $state) {
	var vm = this;
	vm.editingContato = {
		ativo: 'S'
	};
	vm.pessoa = {
		natureza: 'PJ',
		ativo: true,
		contatos: [],
		tipos_ids: [],
		historico_contatos: []
	};
	
	getSetores();
	getRamosAtuacao();
	getOrigens();
	getTiposRelacionamento();
	getTiposContato();
	getProdutos();
	getMotivos();

	function getSetores(){
		Setor.getSetores()
			.then(function(response) {
				vm.setores = response.data;
			});
	}

	function getRamosAtuacao(){
		RamoAtuacao.getRamosAtuacao()
			.then(function(response) {
				vm.ramos_atuacao = response.data;
			});
	}

	function getOrigens(){
		Origem.getOrigens()
			.then(function(response) {
				vm.origens = response.data;
			});
	}

	function getTiposRelacionamento() {
		TipoRelacionamento.getTiposRelacionamento()
			.then(function(response) {
				$scope.tipos = response.data;
			})
	}

	function getTiposContato(){
		TipoContato.getTiposContato()
			.then(function(response) {
				$scope.tipos_contato = response.data;
			});
	}

	function getProdutos(){
		Produto.getProdutos()
			.then(function(response) {
				$scope.produtos = response.data;
			});
	}

	function getMotivos(){
		Motivo.getMotivos()
			.then(function(response) {
				$scope.motivos = response.data;
			});
	}

	function getPessoa() {
		Pessoa.getPessoa(vm.pessoa.id)
			.success(function(response) {
				vm.pessoa = response;
			});
	}

	$scope.insertPessoa = function(redirect) {
		var handleError = function(response) {
			vm.errorMessage = {};
			angular.forEach(response.data, function(messages, field) {
				vm.pessoaForm[field].$setValidity('server', false);
				vm.errorMessage[field] = messages[0];
			});
		}

		var handleSuccess = function(response) {
			vm.pessoaForm.$setPristine();
			vm.enderecoForm.$setPristine();
			toastr.success('Pessoa salva com sucesso.');
			if (redirect) {
				$state.go('dashboard.mailing.list');
			} else {
				getPessoa();
			}
		}

		if (vm.pessoa.id) {
			Pessoa.updatePessoa(vm.pessoa)
				.then(function(response) {
					handleSuccess(response);
				})
				.catch(function(response) {
					handleError(response);
				});
		} else {
			Pessoa.insertPessoa(vm.pessoa)
				.then(function(response) {
					vm.pessoa.id = response.data.id;
					handleSuccess(response);
				})
				.catch(function(response) {
					handleError(response);
				});
		}
	}

	$scope.editContato = function(index) {
		vm.editingContato = angular.copy(vm.pessoa.contatos[index], {});
		vm.editingContatoIndex = index;
	}

	$scope.saveContato = function() {
		if (vm.editingContatoIndex >= 0) {
			vm.pessoa.contatos[vm.editingContatoIndex] = vm.editingContato;
			vm.editingContatoIndex = null;
		} else {
			var dt = new Date();
			var timestamp = dt.getTime();
			vm.editingContato.temp_id = '__' + timestamp + '__';
			vm.pessoa.contatos.push(vm.editingContato);
		}
		vm.editingContato = {
			ativo: 'S'
		};
		vm.contatoForm.$setPristine();
	}

	$scope.deleteContato = function(index) {
		vm.pessoa.contatos.splice(index, 1);
	}

	$scope.boolToStr = function(value) {
		return value ? 'S' : 'N';
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social')
			.then(function(response) {
				var items = response.data.items;
				items.push({id: null, razao_social: '-- Nenhum --'});
				vm.pessoas = items;
			});
	}

	$scope.addHistoricoContato = function() {
		var currentUser = Auth.getCurrentUser();

		var modalInstance = $uibModal.open({
			templateUrl: '/views/mailing/_historico_contato.html',
			controller: 'HistoricoContatoEditCtrl',
			scope: $scope,
			resolve: {
				historico_contato: {
					data: new Date(),
					user: currentUser,
					status: 'A',
					produtos_ids: []
				},
				vm: vm
			}
		});

		modalInstance.result.then(function(historico_contato) {
			var dt = new Date();
			var timestamp = dt.getTime();
			historico_contato.temp_id = '__' + timestamp + '__';

			vm.pessoa.historico_contatos.push(historico_contato);
		});
	}

	$scope.editHistoricoContato = function(index) {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/mailing/_historico_contato.html',
			controller: 'HistoricoContatoEditCtrl',
			scope: $scope,
			resolve: {
				historico_contato: vm.pessoa.historico_contatos[index],
				vm: vm
			}
		})

		modalInstance.result.then(function(historico_contato) {
			vm.pessoa.historico_contatos[index] = historico_contato;
		});
	}

	$scope.deleteHistoricoContato = function(index) {
		if (vm.pessoa.historico_contatos[index].id) {
			vm.pessoa.historico_contatos[index]._destroy = true;
		} else {
			vm.pessoa.historico_contatos.splice(index, 1);
		}
	}

	$scope.buscarCep = function() {
		var cep = vm.pessoa.endereco.cep;
		
		if (!cep) {
			return false;
		};

		Cep.search(cep)
			.then(function(response) {
				var dados = response.data;
				if (dados.tipo == 1) {
					vm.pessoa.endereco.uf = dados.uf;
					vm.pessoa.endereco.cidade = dados.cidade;
					vm.pessoa.endereco.logradouro = dados.logradouro;
					vm.pessoa.endereco.bairro = dados.bairro;
				} else if (dados.tipo == 2) {
					vm.pessoa.endereco.uf = dados.uf;
					vm.pessoa.endereco.cidade = dados.cidade;
				} else {
					toastr.error('Verifique se o CEP foi digitado corretamente.', 'CEP não encontrado');
				}
			}, function() {
				toastr.error('Verifique se o CEP foi digitado corretamente.', 'CEP não encontrado');
			});
	}

	$scope.corrigeSite = function() {		
		if (!vm.pessoa.site || vm.pessoa.site.length == 0) {
			return false;
		}

		if (vm.pessoa.site.indexOf('http://') < 0 && vm.pessoa.site.indexOf('https://') < 0) {
			vm.pessoa.site = 'http://' + vm.pessoa.site;
		}
	}

	$scope.addProduto = function() {
		vm.pessoa.produtos_ids.push(vm.produto.id);
		vm.produto = null;
	}

	$scope.deleteProduto = function(index) {
		vm.pessoa.produtos_ids.splice(index, 1);
	}

	$scope.produtosDisponiveis = function(produtos) {
		var produtosDisponiveis = [];

		angular.forEach(produtos, function(produto) {
			if (!vm.pessoa.produtos_ids ||
				(vm.pessoa.produtos_ids.indexOf(produto.id) < 0 &&
				vm.pessoa.produtos_ids.indexOf('' + produto.id + '') < 0)) {
				produtosDisponiveis.push(produto);
			}
		});

		return produtosDisponiveis;
	}

	$scope.descricaoProduto = function(produtoId, produtos) {
		if (!produtos) return false;
		for (var i = 0; i < produtos.length; i++) {
			if (produtos[i].id == produtoId) {
				return produtos[i].nome_comercial;
			}
		}
		return null;
	}
}]);