app.controller('ProducaoSinteticoCtrl', ['$scope', 'User', 'Pessoa', 'Auth', 'Produto', function ($scope, User, Pessoa, Auth, Produto) {
	$scope.filtros = {};

	$scope.popupMes = {
		opened: false
	};

	$scope.popupDataInicial = {
		opened: false
	};

	$scope.popupDataFinal = {
		opened: false
	};

	$scope.openMes = function() {
		$scope.popupMes.opened = true;
	}

	getUsers();
	getProdutos();

	function getUsers(){
		User.getUsers()
			.then(function(response) {
				$scope.users = response.data;
			});
	}

	function getProdutos(){
		Produto.getProdutos()
			.then(function(response) {
				$scope.produtos = response.data;
			});
	}

	$scope.openDataInicial = function() {
		$scope.popupDataInicial.opened = true;
	}

	$scope.openDataFinal = function() {
		$scope.popupDataFinal.opened = true;
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social')
			.then(function(response) {
				$scope.pessoas = response.data.items;
			});
	}

	$scope.getUrl = function() {
		var token = Auth.getToken();
		return '/api/relatorios/producao-sintetico?token=' + token + '&' + $.param($scope.filtros);
	}
}]);