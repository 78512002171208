app.factory('Erro', ['$http', function($http){
	return {
		getErros: function() {
			return $http.get('/api/erros');
		},
		getErro: function(id) {
			return $http.get('/api/erros/' + id);	
		},
		insertErro: function (erroData) {
			return $http.post('/api/erros', erroData);
		},
		updateErro: function (erroData) {
			return $http.put('/api/erros/' + erroData.id, erroData);
		},
		deleteErro: function(id) {
			return $http.delete('/api/erros/' + id);
		},
		enviarArquivo: function(erroId, arquivo) {
			var fd = new FormData();
			fd.append('arquivo', arquivo);
			return $http.post('/api/erros/' + erroId + '/upload', fd, {
				transformRequest: angular.identity,
				headers: {'Content-Type': undefined}
			});
		}
	};
}])