app.controller('AniversariantesCtrl', ['$scope', 'Auth', function ($scope, Auth) {
	$scope.filtros = {};

	$scope.meses = {
		'1': 'Janeiro',
		'2': 'Fevereiro',
		'3': 'Março',
		'4': 'Abril',
		'5': 'Maio',
		'6': 'Junho',
		'7': 'Julho',
		'8': 'Agosto',
		'9': 'Setembro',
		'10': 'Outubro',
		'11': 'Novembro',
		'12': 'Dezembro'
	};

	$scope.popupMes = {
		opened: false
	};

	$scope.openMes = function() {
		$scope.popupMes.opened = true;
	}

	$scope.getUrl = function() {
		var token = Auth.getToken();
		return '/api/relatorios/aniversariantes?token=' + token + '&' + $.param($scope.filtros);
	}
}]);