app.directive('monthMask', [function () {
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function (scope, element, attrs, ctrl) {
			var formatter = new StringMask('00/00');

			ctrl.$parsers.push(function(value) {
				if (ctrl.$isEmpty(value)) {
					return value;
				}

				var cleanValue = value.replace(/[^\d]/g, '').slice(0,4);
				var formattedValue = formatter.apply(cleanValue).trim().replace(/[^0-9]$/, '');

				if (ctrl.$viewValue !== formattedValue) {
					ctrl.$setViewValue(formattedValue);
					ctrl.$render();
				}

				if (formattedValue.length == 5) {				
					var date = moment(formattedValue, 'DD/MM').toDate();
					return !isNaN(date) ? date : null;
				} else {
					return null;
				}
			});

			ctrl.$formatters.push(function(value) {				
				if (ctrl.$isEmpty(value)) {
					return value;
				}

				return moment(value).format('DD/MM');
			});

			ctrl.$validators.date = function(modelValue, viewValue) {
				console.log(modelValue);
				if (typeof modelValue == 'undefined') {
					return true;
				}

				return modelValue != null || viewValue == null;
			};
		}
	};
}])