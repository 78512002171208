app.controller('UserNewCtrl', ['$scope', '$location', 'toastr', 'User', 'Role', function($scope, $location, toastr, User, Role) {
	var vm = this;
	vm.user = {};

	getRoles();

	function getRoles() {
		Role.getRoles()
			.then(function(response) {
				vm.roles = response.data;
			});
	}

	$scope.insertUser = function() {
		User.insertUser(vm.user)
			.then(function() {
				toastr.success('Usuário salvo com sucesso.');
				$location.path('/users');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.userForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);