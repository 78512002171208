app.controller('ContaListCtrl', ['$scope', 'Conta', 'toastr', 'SweetAlert', '$state', function($scope, Conta, toastr, SweetAlert, $state) {
	$scope.contas = [];
	$scope.contasPerPage = 10;
	$scope.sortType = 'contas.nome';
	$scope.sortReverse = false;

	$scope.refreshContas = function() {
		Conta.getContas($scope.sortType, $scope.sortReverse)
			.then(function(response) {
				$scope.contas = response.data;
			});
	}

	$scope.refreshContas();

	$scope.deleteConta = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção desta conta não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				Conta.deleteConta(id)
					.then(function() {
						toastr.success('Conta removida com sucesso.');
						$scope.refreshContas();
					})
					.catch(function() {
						toastr.error('Não foi possível remover esta conta porque ela está vinculada em outros registros.');
					});
			}
		});
	}

	$scope.changeSort = function(sortType, sortReverse) {
		$scope.sortType = sortType;
		$scope.sortReverse = sortReverse;
		$scope.refreshContas();
	}
}]);