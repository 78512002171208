app.directive('confirmOnExit', ['$rootScope', function($rootScope) {
	return {
		require: '^form',
		link: function($scope, elem, attrs, ctrl) {
			var msg = 'Existem dados que não foram salvos ainda. Deseja realmente sair desta página?';

			var listener = function(e) {
				if (ctrl.$dirty) {	
					e.returnValue = msg;
					return msg;
				}
			};

			$scope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams, options) {
				if (ctrl.$dirty) {
					if (!confirm(msg)) {
						event.preventDefault();
						return false;
					}
				}
				window.removeEventListener('beforeunload', listener);
			});

			window.addEventListener('beforeunload', listener);
		}
	};
}]);