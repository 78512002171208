app.controller('ContaNewCtrl', ['$scope', '$location', '$stateParams', 'toastr', 'Conta', 'CentroCusto', function($scope, $location, $stateParams, toastr, Conta, CentroCusto) {
	var vm = this;
	
	getCentrosCusto();

	function getCentrosCusto(){
		CentroCusto.getCentrosCusto()
			.then(function(response) {
				vm.centros_custo = response.data;
			});
	}

	$scope.insertConta = function() {
		Conta.insertConta(vm.conta)
			.then(function() {
				toastr.success('Conta salva com sucesso.');
				$location.path('/contas');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.contaForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);