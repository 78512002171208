app.controller('FluxoFinanceiroCtrl', ['$scope', 'Banco', 'Conta', 'CentroCusto', 'Pessoa', 'Auth', 'Contrato', function ($scope, Banco, Conta, CentroCusto, Pessoa, Auth, Contrato) {
	$scope.filtros = {
		tipo: 'T',
		contrato: {},
		mostrar: 'A'
	};

	$scope.popupDataInicial = {
		opened: false
	};

	$scope.popupDataFinal = {
		opened: false
	};

	getBancos();
	getContas();
	getCentrosCusto();

	function getContas(){
		Conta.getContas()
			.then(function(response) {
				$scope.contas = response.data;
			});
	}

	function getBancos(){
		Banco.getBancos()
			.then(function(response) {
				$scope.bancos = response.data;
			});
	}

	function getCentrosCusto(){
		CentroCusto.getCentrosCusto()
			.then(function(response) {
				$scope.centros_custo = response.data;
			});
	}

	$scope.openDataInicial = function() {
		$scope.popupDataInicial.opened = true;
	}

	$scope.openDataFinal = function() {
		$scope.popupDataFinal.opened = true;
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social')
			.then(function(response) {
				$scope.pessoas = response.data.items;
			});
	}

	$scope.refreshContratos = function(search) {
		Contrato.getContratos(1, search)
			.then(function(response) {
				$scope.contratos = response.data.items;
			});
	}

	$scope.getUrl = function() {
		var token = Auth.getToken();
		return '/api/relatorios/fluxo-financeiro?token=' + token + '&' + $.param($scope.filtros);
	}
}]);