app.controller('UserEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'User', 'Auth', '$rootScope', 'Role', function($scope, $state, $stateParams, toastr, User, Auth, $rootScope, Role) {
	var vm = this;
	vm.userId = $stateParams.userId;

	getUser();
	getRoles();

	function getUser() {
		User.getUser(vm.userId)
			.success(function(response) {
				vm.user = response;
			});
	}

	function getRoles() {
		Role.getRoles()
			.then(function(response) {
				vm.roles = response.data;
			});
	}

	$scope.updateUser = function() {
		User.updateUser(vm.user)
			.then(function() {
				if (vm.user.id == Auth.getCurrentUser().id) {
					Auth.setCurrentUser(vm.user);
					$rootScope.$broadcast('currentUserChanged');
				}
				toastr.success('Usuário alterado com sucesso.');
				$state.go('dashboard.users.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.userForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);