app.controller('BancoNewCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Banco', function($scope, $state, $stateParams, toastr, Banco) {
	var vm = this;

	$scope.insertBanco = function() {
		Banco.insertBanco(vm.banco)
			.then(function() {
				toastr.success('Banco salvo com sucesso.');
				$state.go('dashboard.banco.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.BancoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);