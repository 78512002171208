app.controller('ImprimirNfseCtrl', ['$scope', '$http', '$uibModalInstance', 'codigoVerificacao', 'numero', 'cnpj', 'toastr', '$window', function($scope, $http, $uibModalInstance, codigoVerificacao, numero, cnpj, toastr, $window) {
  $scope.carregando = false;
  $scope.cnpj = cnpj;
  $scope.codigoVerificacao = codigoVerificacao;
  $scope.numero = numero;

  $scope.getCaptcha = function() {
    $http.get('/api/nfse/captcha')
      .then(function(response) {
        $scope.captcha = response.data.captchaBase64;
        $scope.cookie = response.data.cookie;
      });
  }

  $scope.submit = function() {
    $scope.carregando = true;
    $http.post('/api/nfse/imprimir', {
      cnpj: cnpj,
      codigoVerificacao: codigoVerificacao,
      numero: numero,
      captcha: $scope.textCaptcha,
      stringCookie: $scope.cookie,
    })
      .then(function(response) {
        $window.open('/nfse/' + response.data.arquivo, '_blank');
        $uibModalInstance.dismiss('cancel');
      })
      .catch(function(response) {
        $uibModalInstance.dismiss('cancel');
        toastr.error('Ocorreu um erro ao imprimir a NFS-e.');
      });
  }

  $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
  }

  $scope.getCaptcha();
}]);
