app.controller('ContratoListCtrl', ['$scope', 'Contrato', 'toastr', 'SweetAlert', '$state', function($scope, Contrato, toastr, SweetAlert, $state) {
	$scope.contratos = [];
	$scope.totalContratos = 0;
	$scope.contratosPerPage = 10;
	$scope.sortType = 'contratos.id';
	$scope.sortReverse = true;
	$scope.searchText = '';

	getResultsPage(1, $scope.searchText, $scope.sortType, $scope.sortReverse);

	$scope.pagination = {
	    current: 1
	};

	$scope.refreshContratos = function() {
		getResultsPage($scope.pagination.current, $scope.searchText, $scope.sortType, $scope.sortReverse);
	}

	$scope.pageChanged = function(newPage) {
		getResultsPage(newPage, $scope.searchText, $scope.sortType, $scope.sortReverse);
	}

	$scope.refreshContratos();

	$scope.deleteContrato = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção deste contrato não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				Contrato.deleteContrato(id)
					.then(function() {
						toastr.success('Contrato removido com sucesso.');
						$scope.refreshContratos();
					})
					.catch(function() {
						toastr.error('Não foi possível remover este contrato porque ele está vinculada em outros registros.');
					});
			}
		});
	}

	$scope.changeSort = function(sortType, sortReverse) {
		$scope.sortType = sortType;
		$scope.sortReverse = sortReverse;
		$scope.refreshContratos();
	}

	$scope.abrirProdutos = function(contrato) {
		contrato.mostrar_produtos = !contrato.mostrar_produtos;
		if (!contrato._produtos || (typeof contrato._produtos) != 'object') {
			contrato._produtos = [];
			contrato.carregando = true;	
			Contrato.getContrato(contrato.id)
				.success(function(response) {
					contrato._produtos = response.produtos;
					contrato.carregando = false;
				});
		}
	}

	function getResultsPage(pageNumber, searchText, sortType, sortReverse) {
		Contrato.getContratos(pageNumber, searchText, sortType, sortReverse)
			.then(function(response) {
				$scope.contratos = response.data.items;
				$scope.totalContratos = response.data.count;
			});
	}
}]);