app.controller('HistoricoContatosCtrl', ['$scope', 'toastr', 'User', 'Pessoa', 'HistoricoContato', 'TipoContato', 'Auth', function($scope, toastr, User, Pessoa, HistoricoContato, TipoContato, Auth) {
	$scope.currentUser = Auth.getCurrentUser();
	$scope.contatos = [];
	$scope.totalContatos = 0;
	$scope.contatosPerPage = 10;
	$scope.sortType = 'data';
	$scope.sortReverse = false;
	$scope.filtros = {
		'filtrar_por': 'data',
		'user_id': $scope.currentUser.id
	};

	$scope.pagination = {
	    current: 1
	};

	$scope.popupDataInicial = {
		opened: false
	};

	$scope.popupDataFinal = {
		opened: false
	};

	getUsers();
	getTiposContato();
	getResultsPage(1, $scope.sortType, $scope.sortReverse, $scope.filtros);

	$scope.openDataInicial = function() {
		$scope.popupDataInicial.opened = true;
	}

	$scope.openDataFinal = function() {
		$scope.popupDataFinal.opened = true;
	}

	$scope.refreshContatos = function() {
		getResultsPage($scope.pagination.current, $scope.sortType, $scope.sortReverse, $scope.filtros);
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social')
			.then(function(response) {
				$scope.pessoas = response.data.items;
			});
	}

	$scope.pageChanged = function(newPage) {
		getResultsPage(newPage, $scope.sortType, $scope.sortReverse, $scope.filtros);
	}

	$scope.changeSort = function(sortType, sortReverse) {
		$scope.sortType = sortType;
		$scope.sortReverse = sortReverse;
		$scope.refreshContatos();
	}

	$scope.preencherComDataHoje = function(data) {
		$scope.filtros[data] = new Date();
	}

	function getUsers(){
		User.getUsers()
			.then(function(response) {
				$scope.users = response.data;
			});
	}

	function getTiposContato(){
		TipoContato.getTiposContato()
			.then(function(response) {
				$scope.tipos_contato = response.data;
			});
	}

	function getResultsPage(pageNumber, sortType, sortReverse, filtros) {
		HistoricoContato.getHistoricosContato(pageNumber, sortType, sortReverse, filtros)
			.then(function(response) {
				$scope.contatos = response.data.items;
				$scope.totalContatos = response.data.count;
			});
	}	
}]);