app.factory('Pessoa', ['$http', function($http){
	return {
		TipoCadastro: {
			Relacionamento: 'Relacionamento',
			Mailing: 'Mailing'
		},
		getPessoas: function(offset, limit, search, columns, tipoId, trazerContatos, trazerContratos, tipoCadastro, ativo, ramoAtuacao, produtosOfertar, cidade) {
			var params = '?offset=' + offset + '&limit=' + limit + '&q=' + search;
			if (columns) { params += '&columns=' + columns; }
			if (tipoId) { params += '&tipo_id=' + tipoId; }
			if (trazerContatos) { params += '&contatos=1'; }
			if (trazerContratos) { params += '&contratos=1'; }
			if (tipoCadastro) { params += '&tipo_cadastro=' + tipoCadastro; }
			if (ativo) { params += '&ativo=' + ativo; }
			if (ramoAtuacao) { params += '&ramo_atuacao=' + ramoAtuacao; }
			if (produtosOfertar) { params += '&produtos_ofertar=' + produtosOfertar; }
			if (cidade) { params += '&cidade=' + cidade.nome + '&uf=' + cidade.uf; }
			return $http.get('/api/pessoas' + params);
		},
		getCidades: function() {
			return $http.get('/api/pessoas/cidades');	
		},
		getPessoa: function(id) {
			return $http.get('/api/pessoas/' + id);	
		},
		insertPessoa: function (pessoaData) {
			return $http.post('/api/pessoas', pessoaData);
		},
		updatePessoa: function (pessoaData) {
			return $http.put('/api/pessoas/' + pessoaData.id, pessoaData);
		},
		deletePessoa: function(id) {
			return $http.delete('/api/pessoas/' + id);
		},
		deleteContato: function(pessoaId, contatoId) {
			return $http.delete('/api/pessoas/' + pessoaId + '/contatos/' + contatoId);
		},
		enviarArquivo: function(pessoaId, arquivo) {
			var fd = new FormData();
			fd.append('arquivo', arquivo);
			return $http.post('/api/pessoas/' + pessoaId + '/upload', fd, {
				transformRequest: angular.identity,
				headers: {'Content-Type': undefined}
			});
		}
	};
}])