app.controller('ContratoPickerCtrl', ['$scope', '$uibModalInstance', 'Contrato', function($scope, $uibModalInstance, Contrato) {
	$scope.form = {
		contrato: {}
	};
	$scope.contratos = [];

	$scope.refreshContratos = function(search) {
		Contrato.getContratos(1, search)
			.then(function(response) {
				$scope.contratos = response.data.items;
			});
	}

    $scope.import = function() {
    	$uibModalInstance.close($scope.form.contrato ? $scope.form.contrato.id : null);
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    }
}]);