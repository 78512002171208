app.controller('HomeCtrl', ['$scope', 'Atendimento', 'Auth', 'ROLE_ADMINISTRADOR', 'Produto', 'User', 'Pessoa', function ($scope, Atendimento, Auth, ROLE_ADMINISTRADOR, Produto, User, Pessoa) {
	$scope.user = Auth.getCurrentUser();
	$scope.pessoa = {};

	getProdutos();
	
	if ($scope.user.role_id == ROLE_ADMINISTRADOR) {
		getUsers();
	}

	function getProdutos(){
		Produto.getProdutos()
			.then(function(response) {
				$scope.produtos = response.data;
			});
	}

	function getUsers(){
		User.getUsers()
			.then(function(response) {
				$scope.users = response.data;
			});
	}

	$scope.ehAdministrador = function() {
		return $scope.user.role_id == ROLE_ADMINISTRADOR;
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social', null, true)
			.then(function(response) {
				$scope.pessoas = response.data.items;
			});
	}

	$scope.refreshAtendimentos = function() {
		var userId = $scope.user.role_id == ROLE_ADMINISTRADOR ? $scope.userId : $scope.user.id;
		var pessoaId = $scope.pessoa.selected ? $scope.pessoa.selected.id : null;
		Atendimento.getAtendimentosPendentes(userId, pessoaId, $scope.produtoId)
			.then(function(response) {
				$scope.atendimentos = response.data;
			});
	}

	$scope.refreshAtendimentos();
}]);