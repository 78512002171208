app.controller('PessoaListCtrl', ['$scope', 'Pessoa', 'toastr', 'SweetAlert', '$state', 'Auth', '$uibModal', 'Produto', 'TipoContato', 'Motivo', 'RamoAtuacao', 'TipoRelacionamento', function($scope, Pessoa, toastr, SweetAlert, $state, Auth, $uibModal, Produto, TipoContato, Motivo, RamoAtuacao, TipoRelacionamento) {
	$scope.pessoas = [];
	$scope.totalPessoas = 0;
	$scope.pessoasPerPage = 10;
	$scope.searchText = '';
	getResultsPage(1, $scope.searchText);
	$scope.columns = {
		'id': true,
		'ativo': false,
		'natureza': false,
		'origem': false,
		'razao_social': true,
		'nome_fantasia': false,
		'isncricao_estadual': false,
		'cnpj': true,
		'telefone': false,
		'email': false,
		'site': false,
		'cliente_desde': false,
		'receita_anual': false,
		'numero_funcionarios': false,
		'ramo_atuacao': false,
		'telefone_principal': true,
		'indicado_por': false,
		'data_fundacao': false,
		'tipo_cadastro': false,
		'inscricao_municipal': false,
		'email_cobranca': false
	};

	$scope.pagination = {
	    current: 1
	};

	$scope.refreshPessoas = function() {
		getResultsPage($scope.pagination.current, $scope.searchText);
	}

	$scope.pageChanged = function(newPage) {
		getResultsPage(newPage, $scope.searchText);
	}

	$scope.deletePessoa = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção desta pessoa não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				Pessoa.deletePessoa(id)
					.then(function() {
						toastr.success('Pessoa removida com sucesso.');
						$scope.refreshPessoas();
					})
					.catch(function() {
						toastr.error('Não foi possível remover esta pessoa porque ela está vinculada em outros registros.');
					});
			}
		});
	}

	$scope.toggleColumn = function(column) {
		$scope.columns[column] = !$scope.columns[column];
	}

	$scope.addHistoricoContato = function(pessoaId) {
		var currentUser = Auth.getCurrentUser();
		var hoje = new Date();

		Pessoa.getPessoa(pessoaId)
			.success(function(response) {
				var pessoa = response;

				var modalInstance = $uibModal.open({
					templateUrl: '/views/pessoas/_historico_contato.html',
					controller: 'HistoricoContatoEditCtrl',
					scope: $scope,
					resolve: {
						historico_contato: {
							data: Date.parse(hoje.toJSON()),
							user: currentUser,
							status: 'A',
							produtos_ids: []
						},
						vm: {
							pessoa: pessoa
						}
					}
				});

				modalInstance.result.then(function(historico_contato) {
					var dt = new Date();
					var timestamp = dt.getTime();
					historico_contato.temp_id = '__' + timestamp + '__';
					pessoa.historico_contatos.push(historico_contato);

					Pessoa.updatePessoa(pessoa)
						.then(function() {
							toastr.success('Histórico criado com sucesso.');
						})
						.catch(function(response) {
							toastr.error('Ocorreu um erro.');
						});
				});
			});
	}

	function getResultsPage(pageNumber, searchText) {
		Pessoa.getPessoas((pageNumber-1) * $scope.pessoasPerPage, $scope.pessoasPerPage, searchText, null, $scope.relacionamento, null, null, Pessoa.TipoCadastro.Relacionamento, $scope.ativo, $scope.ramo_atuacao_id, null, $scope.cidade)
			.then(function(response) {
				$scope.pessoas = response.data.items;
				$scope.totalPessoas = response.data.count;
			})
			.catch(function() {
				$state.go('dashboard.home');
			});
	}

	function getProdutos(){
		Produto.getProdutos()
			.then(function(response) {
				$scope.produtos = response.data;
			});
	}

	function getTiposContato(){
		TipoContato.getTiposContato()
			.then(function(response) {
				$scope.tipos_contato = response.data;
			});
	}


	function getMotivos(){
		Motivo.getMotivos()
			.then(function(response) {
				$scope.motivos = response.data;
			});
	}

	function getCidades(){
		Pessoa.getCidades()
			.then(function(response) {
				$scope.cidades = response.data;
			});
	}

	function getTiposRelacionamento() {
		TipoRelacionamento.getTiposRelacionamento()
			.then(function(response) {
				$scope.tipos = response.data;
			})
	}

	function getRamosAtuacao(){
		RamoAtuacao.getRamosAtuacao()
			.then(function(response) {
				$scope.ramos_atuacao = response.data;
			});
	}

	getProdutos();
	getTiposContato();
	getMotivos();
	getCidades();
	getTiposRelacionamento();
	getRamosAtuacao();
}]);