app.controller('ContratoNewCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Contrato', 'Conta', 'CentroCusto', 'Banco', 'Produto', 'Pessoa', '$uibModal', 'ModuloProduto', 'Funcao', '$http', 'FormaPagamento', function($scope, $state, $stateParams, toastr, Contrato, Conta, CentroCusto, Banco, Produto, Pessoa, $uibModal, ModuloProduto, Funcao, $http, FormaPagamento) {
	var vm = this;

	vm.editingProduto = {
		tipo_cobranca: 'M',
		recorrente: 'M'
	};

	vm.contrato = {
		modulos_produtos: [],
		status: 'A',
		bloqueado: 'N',
		renova_automaticamente: 'N',
		valores: []
	};

	getProdutos();
	getModulosProdutos();
	getFuncoes();
	getContas();
	getBancos();
	getCentrosCusto();
	getFormasPagamento();

	vm.servicos = [];
	$http.get('/servicos.json')
		.then(function(response) {
			vm.servicos = response.data;
		});


	function getProdutos(){
		Produto.getProdutos()
			.then(function(response) {
				vm.produtos = response.data;
			});
	}

	function getModulosProdutos(){
		ModuloProduto.getModulosProdutos()
			.then(function(response) {
				vm.modulos_produtos = response.data;
			});
	}

	function getFuncoes() {
		Funcao.getFuncoes()
			.then(function(response) {
				vm.funcoes = response.data;
			});
	}

	function getContas(){
		Conta.getContas()
			.then(function(response) {
				vm.contas = response.data;
			});
	}

	function getBancos(){
		Banco.getBancos()
			.then(function(response) {
				vm.bancos = response.data;
			});
	}

	function getCentrosCusto(){
		CentroCusto.getCentrosCusto()
			.then(function(response) {
				vm.centros_custo = response.data;
			});
	}

	function getFormasPagamento(){
		FormaPagamento.getFormasPagamento()
			.then(function(response) {
				vm.formas_pagamento = response.data;
			});
	}

	$scope.insertContrato = function() {
		Contrato.insertContrato(vm.contrato)
			.then(function() {
				toastr.success('Contrato salvo com sucesso.');
				$state.go('dashboard.contratos.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.contratoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}

	vm.popupInicioVigencia = {
		opened: false
	};

	vm.popupFimVigencia = {
		opened: false
	};

	vm.popupDataPagamento = {
		opened: false
	};

	vm.popupDataPrimeiraCobranca = {
		opened: false
	};

	vm.popupDataVenda = {
		opened: false
	};

	$scope.openInicioVigencia = function() {
		vm.popupInicioVigencia.opened = true;
	}

	$scope.openFimVigencia = function() {
		vm.popupFimVigencia.opened = true;
	}

	$scope.openDataPrimeiraCobranca = function() {
		vm.popupDataPrimeiraCobranca.opened = true;
	}

	$scope.openDataVenda = function() {
		vm.popupDataVenda.opened = true;
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social')
			.then(function(response) {
				vm.pessoas = response.data.items;
			});
	}

	$scope.addModuloProduto = function() {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/contratos/_modulo.html',
			controller: 'ContratoModuloProdutoEditCtrl',
			scope: $scope,
			resolve: {
				modulo_produto: {
					funcoes_ids: []
				}
			}
		});

		modalInstance.result.then(function(modulo_produto) {
			console.log(modulo_produto);
			vm.contrato.modulos_produtos.push(modulo_produto);
		});
	}

	$scope.editModuloProduto = function(index) {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/contratos/_modulo.html',
			controller: 'ContratoModuloProdutoEditCtrl',
			scope: $scope,
			resolve: {
				modulo_produto: vm.contrato.modulos_produtos[index]
			}
		});

		modalInstance.result.then(function(modulo_produto) {
			vm.contrato.modulos_produtos[index] = modulo_produto;
		});
	}

	$scope.deleteModuloProduto = function(index) {
		vm.contrato.modulos_produtos.splice(index, 1);
	}

	$scope.modulosDisponiveis = function(moduloAtual) {
		var modulosDisponiveis = [];
		var modulosUsados = [];

		angular.forEach(vm.contrato.modulos_produtos, function(modulo_produto) {
			modulosUsados.push(modulo_produto.modulo.id);
		})

		angular.forEach(vm.modulos_produtos, function(modulo_produto) {
			if (modulosUsados.indexOf(modulo_produto.id) < 0 ||
				(moduloAtual.modulo && moduloAtual.modulo.id == modulo_produto.id)) {
				modulosDisponiveis.push(modulo_produto);
			}
		});

		return modulosDisponiveis;
	}

	$scope.addContratoValor = function() {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/contratos/_valor.html',
			controller: 'ContratoValorEditCtrl',
			scope: $scope,
			resolve: {
				valor: {
					data: new Date(),
					valor: 0
				}
			}
		});

		modalInstance.result.then(function(valor) {
			vm.contrato.valores.push(valor);
		});
	}

	$scope.editContratoValor = function(index) {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/contratos/_valor.html',
			controller: 'ContratoValorEditCtrl',
			scope: $scope,
			resolve: {
				valor: vm.contrato.valores[index]
			}
		});

		modalInstance.result.then(function(valor) {
			vm.contrato.valores[index] = valor;
		});
	}

	$scope.deleteContratoValor = function(index) {
		vm.contrato.valores.splice(index, 1);
	}

	$scope.editProduto = function(index) {
		vm.editingProduto = angular.copy(vm.contrato.produtos[index], {});
		vm.editingProdutoIndex = index;
	}

	$scope.saveProduto = function() {
		if (vm.editingProdutoIndex >= 0) {
			vm.contrato.produtos[vm.editingProdutoIndex] = vm.editingProduto;
			vm.editingProdutoIndex = null;
		} else {
			var dt = new Date();
			var timestamp = dt.getTime();
			vm.editingProduto.temp_id = '__' + timestamp + '__';
			vm.contrato.produtos.push(vm.editingProduto);
		}
		vm.editingProduto = {
			tipo_cobranca: 'M',
			recorrente: 'N'
		};
		vm.produtoForm.$setPristine();
	}

	$scope.deleteProduto = function(index) {
		vm.contrato.produtos.splice(index, 1);
	}

	$scope.$watch('vm.contrato.fim_vigencia', function(newVal) {
		if (!vm.contrato) {
			return false;
		}

		if (newVal) {
			var fimVigencia = (new Date(newVal)).getTime();
			var hoje = (new Date()).getTime();
			vm.contrato.status = vm.contrato.renova_automaticamente == 'N' && fimVigencia < hoje ? 'I' : 'A';
		} else {
			vm.contrato.status = 'A';
		}
	});

	$scope.$watch('vm.contrato.renova_automaticamente', function(newVal) {
		if (!vm.contrato) {
			return false;
		}

		if (newVal) {
			var fimVigencia = (new Date(vm.contrato.fim_vigencia)).getTime();
			var hoje = (new Date()).getTime();
			vm.contrato.status = newVal == 'N' && fimVigencia > hoje ? 'I' : 'A';
		} else {
			vm.contrato.status = 'A';
		}
	});
	
	$scope.refreshProgramadores = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social', 2)
			.then(function(response) {
				vm.programadores = response.data.items;
			});
	}
	
	$scope.refreshVendedores = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social', 11)
			.then(function(response) {
				vm.vendedores = response.data.items;
			});
	}

	$scope.importarModulos = function() {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/contratos/_picker.html',
			controller: 'ContratoPickerCtrl',
			scope: $scope
		});

		modalInstance.result.then(function(contratoId) {
			if (contratoId) {
				Contrato.getContrato(contratoId)
					.success(function(response) {
						for (var i = 0; i < response.modulos_produtos.length; i++) {
							vm.contrato.modulos_produtos.push(response.modulos_produtos[i]);
						}
					});
			}
		});
	}
}]);