app.controller('ContratoValorEditCtrl', ['$scope', '$uibModalInstance', 'valor', function($scope, $uibModalInstance, valor) {
	$scope.valor = angular.copy(valor, {});

	$scope.popupData = {
		opened: false
	};

	$scope.openData = function() {
		$scope.popupData.opened = true;
	}

	$scope.save = function() {
		$uibModalInstance.close($scope.valor);
	}

	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');
	}
}]);