app.controller('LancamentoListCtrl', ['$scope', 'Lancamento', 'toastr', 'SweetAlert', '$state', '$stateParams', 'Pessoa', 'Conta', 'Banco', 'Pessoa', 'FormaPagamento', 'CentroCusto', 'Auth', function($scope, Lancamento, toastr, SweetAlert, $state, $stateParams, Pessoa, Conta, Banco, Pessoa, FormaPagamento, CentroCusto, Auth) {
	$scope.lancamentos = [];
	$scope.totalLancamentos = 0;
	$scope.lancamentosPerPage = 12;
	$scope.searchText = $stateParams.searchText;
	$scope.sortType = $stateParams.sortType;
	$scope.sortReverse = $stateParams.sortReverse;
	$scope.pessoaId = $stateParams.pessoaId;
    $scope.token = Auth.getToken();
	$scope.soma = 0.0;
	$scope.mostrarSomas = false;
	$scope.selecionados = [];
	$scope.filtros = $stateParams.filtros || {
		'filtrar_por': 'data_emissao'
	};
	$scope.columns = $stateParams.columns || {
		'id': true,
		'nome': true,
		'data_emissao': false,
		'banco': false,
		'conta': true,
		'data_vencimento': true,
		'valor_liquido': true,
		'historico': true,
		'cheque': false,
		'data_pagamento': true,
		'documento': false,
		'forma_pagamento': false,
		'centro_custo': false
	};
	
	getContas();
	getBancos();
	getFormasPagamento();
	getCentrosCusto();
	getResultsPage(1, $scope.searchText, $scope.sortType, $scope.sortReverse, $scope.pessoaId, $scope.filtros);

	if ($scope.pessoaId) {
		Pessoa.getPessoa($scope.pessoaId)
			.then(function(response) {
				$scope.pessoa = response.data;
			});
	}

	$scope.pagination = {
	    current: 1
	};

	$scope.popupDataInicial = {
		opened: false
	};

	$scope.popupDataFinal = {
		opened: false
	};

	$scope.openDataInicial = function() {
		$scope.popupDataInicial.opened = true;
	}

	$scope.openDataFinal = function() {
		$scope.popupDataFinal.opened = true;
	}

	$scope.refreshLancamentos = function() {
		$scope.selecionados = [];
		getResultsPage($scope.pagination.current, $scope.searchText, $scope.sortType, $scope.sortReverse, $scope.pessoaId, $scope.filtros);
	}

	$scope.pageChanged = function(newPage) {
		getResultsPage(newPage, $scope.searchText, $scope.sortType, $scope.sortReverse, $scope.pessoaId, $scope.filtros);
	}

	$scope.changeSort = function(sortType, sortReverse) {
		$scope.sortType = sortType;
		$scope.sortReverse = sortReverse;
		$scope.refreshLancamentos();
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social', null, false, false, Pessoa.TipoCadastro.Relacionamento)
			.then(function(response) {
				var items = response.data.items;
				items.unshift({
					id: '',
					razao_social: '- Selecione -'
				});
				$scope.pessoas = items;
			});
	}

	$scope.deleteLancamento = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção deste lançamento não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				Lancamento.deleteLancamento(id)
					.then(function() {
						toastr.success('Lançamento removido com sucesso.');
						$scope.refreshLancamentos();
					})
					.catch(function() {
						toastr.error('Não foi possível remover este lançamento porque ele está vinculada em outros registros.');
					});
			}
		});
	}

	$scope.duplicateLancamento = function(id) {
		SweetAlert.swal({
			title: 'Duplicar Lançamento',
			text: 'Quantas vezes você deseja duplicar este lançamento?',
			type: 'input',
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#46B999",
			confirmButtonText: "Duplicar",
			showCancelButton: true,
			closeOnConfirm: false,
			inputValue: 1,
			inputPlaceholder: 'Digite a quantidade',
			showLoaderOnConfirm: true
		}, function(inputValue) {
			var quantity = parseInt(inputValue);

			if (isNaN(quantity) || quantity < 1) {
				SweetAlert.showInputError('Você precisa digitar um valor.');
				return false;
			}

			Lancamento.duplicateLancamento(id, quantity)
				.then(function(response) {
					SweetAlert.close();

					if (quantity == 1) {
						$state.go('dashboard.lancamentos.edit', {
							'lancamentoId': response.data.id,
							'pessoaId': $stateParams.pessoaId,
							'searchText': $stateParams.searchText,
							'columns': $stateParams.columns,
							'sortType': $stateParams.sortType,
							'sortReverse': $stateParams.sortReverse,
							'filtros': $stateParams.filtros
						});
					} else {
						$scope.refreshLancamentos();
					}

					toastr.success(quantity > 1 ? 'Lançamentos duplicados com sucesso.' : 'Lançamento duplicado com sucesso.');
				});
		});
	}

	$scope.foiSelecionado = function(id) {
		for (var i = 0; i < $scope.selecionados.length; i++) {
			if ($scope.selecionados[i].id == id) {
				return true;
			}
		}
		return false;
	}

	$scope.toggleSelecionado = function(lancamento) {
		var idx = -1;
		for (var i = 0; i < $scope.selecionados.length && idx == -1; i++) {
			if ($scope.selecionados[i].id == lancamento.id) {
				idx = i;
			}
		}
		if (idx >= 0) {
			$scope.selecionados.splice(idx, 1);
		} else {
			$scope.selecionados.push(lancamento);
		}
	}

	$scope.toggleColumn = function(column) {
		$scope.columns[column] = !$scope.columns[column];
	}

	$scope.numColunasAtivas = function() {
		var num = 0;
		angular.forEach($scope.columns, function(column) {
			if (column) {
				num++;
			}
		});
		return num;
	}

	$scope.clickConferido = function(lancamentoId, conferido) {
		Lancamento.updateLancamento({
			id: lancamentoId,
			conferido: conferido ? 1 : 0
		}).then(function() {
			toastr.success('Lancamento ' + lancamentoId + (conferido ? ' marcado' : ' desmarcado') + ' como Conferido');
		});
	}

	$scope.creditosSelecionados = function() {
		var soma = 0;
		for (var i = 0; i < $scope.selecionados.length; i++) {
			if ($scope.selecionados[i].tipo == 'C') {
				soma += $scope.selecionados[i].valor_liquido;
			}
		}
		return soma;
	}

	$scope.debitosSelecionados = function() {
		var soma = 0;
		for (var i = 0; i < $scope.selecionados.length; i++) {
			if ($scope.selecionados[i].tipo == 'D') {
				soma += $scope.selecionados[i].valor_liquido;
			}
		}
		return soma;
	}

	function getContas(){
		Conta.getContas()
			.then(function(response) {
				$scope.contas = response.data;
			});
	}

	function getBancos(){
		Banco.getBancos()
			.then(function(response) {
				$scope.bancos = response.data;
			});
	}


	function getFormasPagamento(){
		FormaPagamento.getFormasPagamento()
			.then(function(response) {
				$scope.formas_pagamento = response.data;
			});
	}

	function getCentrosCusto(){
		CentroCusto.getCentrosCusto()
			.then(function(response) {
				$scope.centros_custo = response.data;
			});
	}

	function getResultsPage(pageNumber, searchText, sortType, sortReverse, pessoaId, filtros) {
		Lancamento.getLancamentos(pageNumber, searchText, sortType, sortReverse, pessoaId, filtros)
			.then(function(response) {
				$scope.lancamentos = response.data.items;
				$scope.totalLancamentos = response.data.count;
				$scope.saldo = response.data.saldo;
				$scope.creditos = response.data.creditos;
				$scope.debitos = response.data.debitos;

				var f = angular.copy(filtros);
				delete f.filtrar_por;
				delete f.page;
				delete f.q;
				delete f.sort;
				delete f.dir;
				delete f.pessoaId;
				$scope.mostrarSomas = false;

				angular.forEach(Object.keys(f), function(k) {
					if (f[k]) $scope.mostrarSomas = true;
				});
			});
	}
}]);