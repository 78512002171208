app.controller('SolicitacaoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'solicitacao', '$uibModalInstance', 'SweetAlert', 'Auth', 'atendimento', '$http', 'toastr', function($scope, $state, $stateParams, toastr, solicitacao, $uibModalInstance, SweetAlert, Auth, atendimento, $http, toastr) {
	$scope.solicitacao = angular.copy(solicitacao, {});
	$scope.baseDate = new Date();

	$scope.popupTestadoEm = {
		opened: false
	};

	$scope.popupImplantadoEm = {
		opened: false
	};

	$scope.openTestadoEm = function() {
		$scope.popupTestadoEm.opened = true;
	}

	$scope.openImplantadoEm = function() {
		$scope.popupImplantadoEm.opened = true;
	}

    $scope.save = function() {
    	if ($scope.solicitacao.implantado_por_id) {
	    	SweetAlert.swal({
				title: 'Finalizar Solicitação',
				text: 'Deseja Enviar Notificação ao Cliente da liberação desta Solicitação?',
				type: 'warning',
				cancelButtonText: 'Não',
				confirmButtonColor: "#46B999",
				confirmButtonText: "Sim",
				showCancelButton: true,
				closeOnConfirm: false
	    	}, function(isConfirm) {
	    		if (isConfirm) {
	    			$http.post('/api/atendimentos/' + atendimento.id + '/enviar-email', $scope.solicitacao)	
	    				.then(function() {
	    					SweetAlert.close();
					      $uibModalInstance.close($scope.solicitacao);
	    				})
	    				.catch(function() {
	    					toastr.error('Ocorreu um erro ao enviar e-mail.');
	    				});
	    		} else {
	    			SweetAlert.close();
			      $uibModalInstance.close($scope.solicitacao);
	    		}
	    	});
    	} else {
	        $uibModalInstance.close($scope.solicitacao);
    	}
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    }

    $scope.addModuloProduto = function() {
		SweetAlert.swal({
			title: 'Novo Módulo',
			text: 'Digite abaixo a descrição do novo módulo:',
			type: 'input',
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#46B999",
			confirmButtonText: "Criar",
			showCancelButton: true,
			closeOnConfirm: false,
			inputPlaceholder: 'Descrição',
			showLoaderOnConfirm: true
		}, function(inputValue) {
			if (!inputValue.length > 0) {
				SweetAlert.showInputError('Você precisa digitar a descrição.');
				return false;
			}

			$scope.createModuloProduto(inputValue, function(modulo_produto) {			
				$scope.solicitacao.modulo_produto = modulo_produto;
				SweetAlert.close();
			});
		});
    }

	$scope.$on('modal.closing', function(e, reason, closed) {
		if (reason != 'cancel' && !closed) {
			e.preventDefault();
		}
	});

	$scope.$watch('solicitacao.prioridade', function(newVal, oldVal) {
		if ($scope.solicitacao.prioridade && $scope.solicitacao.prioridade.prazo) {
			var dt = new Date(atendimento.data_abertura);
			dt.setDate(dt.getDate() + $scope.solicitacao.prioridade.prazo);
			$scope.solicitacao.prazo_previsto = Date.parse(dt.toJSON());
		} else {
			$scope.solicitacao.prazo_previsto = null;
		}
	}, true);

	$scope.$watch('solicitacao.implantado_por_id', function(newVal, oldVal) {
		if (newVal != null && $scope.solicitacao.prazo_realizado == null) {
			var hoje = new Date();
			$scope.solicitacao.prazo_realizado = Date.parse(hoje.toJSON());
		}
	});

	$scope.$watch('solicitacao.testado_em', function(newVal, oldVal) {
		if (newVal != null && $scope.solicitacao.testado_por_id == null) {
			var user = Auth.getCurrentUser();
			$scope.solicitacao.testado_por_id = user.id;
		}
	});

	$scope.$watch('solicitacao.implantado_em', function(newVal, oldVal) {
		if (newVal != null && $scope.solicitacao.implantado_por_id == null) {
			var user = Auth.getCurrentUser();
			$scope.solicitacao.implantado_por_id = user.id;
		}
	});
}]);