app.factory('HistoricoContato', ['$http', function($http){
	return {
		getHistoricosContato: function(pageNumber, sortType, sortReverse, filtros) {
			filtros.page = pageNumber;
			filtros.sort = sortType;
			filtros.dir = sortReverse ? 'desc' : 'asc';
			filtros.pessoa_id = filtros.pessoa ? filtros.pessoa.id : '';
			return $http({
				url: '/api/historicos-contato',
				method: 'GET', 
				params: filtros
			});
		}
	};
}])