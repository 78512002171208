app.factory('AuthInterceptor', ['$q', 'Auth', 'AUTH_EVENTS', '$rootScope', '$injector', function ($q, Auth, AUTH_EVENTS, $rootScope, $injector) {
	return {
		request: function (config) {
			var token = Auth.getToken();
			if (token) {
				config.headers['Authorization'] = 'Bearer ' + token;
			}
			/*if (config.url.indexOf('/api') == 0) {
				config.url = "http://adminx.escritoriomovel.com/" + config.url;
			}*/
			return config;
		},
		responseError: function (response) {
			$rootScope.$broadcast({
				401: AUTH_EVENTS.notAuthenticated,
				403: AUTH_EVENTS.notAuthorized,
				419: AUTH_EVENTS.sessionTimeout,
				440: AUTH_EVENTS.sessionTimeout
			} [response.status], response);
			return $q.reject(response);
		}
	};
}])