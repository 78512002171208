app.controller('LoginCtrl', ['$scope', '$http', 'Auth', '$state', 'toastr', function ($scope, $http, Auth, $state, toastr) {
	$scope.login = function() {
		$http.post('/api/auth', {email: $scope.email, password: $scope.password})
			.then(function(response) {
				Auth.saveToken(response.data.token);
				Auth.setCurrentUser(response.data.user);
				Auth.setPermissions(response.data.permissions);
				toastr.success('Sessão iniciada com sucesso.');
				$state.go('dashboard.home');
			})
			.catch(function(response) {
				toastr.error(response.data.message);
			});
	}
}]);