app.controller('NfseCtrl', ['$scope', '$state', 'toastr', '$http', 'Cep', '$uibModal', 'Lancamento', '$stateParams', 'Settings', 'Auth', '$location', function($scope, $state, toastr, $http, Cep, $uibModal, Lancamento, $stateParams, Settings, Auth, $location) {
  var vm = this;
  var hoje = new Date();
  vm.carregando = false;
  vm.nfseGerada = false;
  vm.nfse = {
    data_emissao: hoje,
    codigo_municipio: {
      nome: 'PORTO ALEGRE',
      uf: 'RS',
      codigo_ibge: '4314902'
    }
  };
  vm.municipios = [];
  vm.municipios_tomador = [];
  vm.naturezaOperacao = {
    '1': 'Tributação no município',
    '2': 'Tributação fora do município',
    '3': 'Isenção',
    '4': 'Imune',
    '5': 'Exigibilidade suspensa por decisão judicial',
    '6': 'Exigibilidade suspensa por procedimento administrativo'
  };
  vm.regimeEspecialTributacao = {
    '1': 'Microempresa Municipal',
    '2': 'Estimativa',
    '3': 'Sociedade de Profissionais',
    '4': 'Cooperativa',
    '5': 'MEI do Simples Nacional',
    '6': 'ME ou EPP do Simples Nacional'
  };

  vm.popupDataEmissao = {
    opened: false
  };

  function getConfiguacoes() {
    Settings.getSettings()
      .success(function(response) {
        vm.nfse.codigo_municipio = response.codigo_municipio_nfse;
        vm.nfse.natureza_operacao = response.natureza_operacao_nfse;
        vm.nfse.regime_especial_tributacao = response.regime_especial_tributacao_nfse;
        vm.nfse.item_lista_servico = response.item_lista_servico_nfse;
        vm.nfse.codigo_tributacao_municipio = response.codigo_tributacao_municipio_nfse;
      });
  }

  $scope.openDataEmissao = function() {
    vm.popupDataEmissao.opened = true;
  }

  vm.servicos = [];
  $http.get('/servicos.json')
    .then(function(response) {
      vm.servicos = response.data;

      if ($stateParams.lancamentoId > 0 || $location.search().lancamentoId > 0) {
        $scope.preencherDados($stateParams.lancamentoId || $location.search().lancamentoId);
      }
    });

  $scope.preencherDados = function(lancamentoId) {
    Lancamento.getLancamento(lancamentoId)
      .then(function(response) {
        vm.nfse.lancamento_id = $stateParams.lancamentoId;
        vm.nfse.valor_servicos = response.data.valor_liquido;
        vm.nfse.cpf_cnpj_tomador = response.data.pessoa.cnpj;
        vm.nfse.inscricao_municipal = response.data.pessoa.inscricao_municipal;
        vm.nfse.razao_social_tomador = response.data.pessoa.razao_social;
        vm.nfse.telefone_tomador = response.data.pessoa.telefone_principal;
        vm.nfse.email_tomador = response.data.pessoa.email;
        vm.nfse.discriminacao = response.data.discriminacao_servicos_nfe;
        vm.nfse.item_lista_servico = response.data.item_lista_servicos + "";
        vm.nfse.codigo_tributacao_municipio = response.data.cod_tributacao_municipio + "";

        if (response.data.pessoa.endereco) {
          vm.nfse.endereco_tomador = response.data.pessoa.endereco.logradouro;
          vm.nfse.numero_endereco_tomador = response.data.pessoa.endereco.numero;
          vm.nfse.complemento_endereco_tomador = response.data.pessoa.endereco.complemento;
          vm.nfse.cep_tomador = response.data.pessoa.endereco.cep;
          vm.nfse.bairro_tomador = response.data.pessoa.endereco.bairro;

          if (response.data.pessoa.endereco.cidade) {
            vm.nfse.cidade_tomador = {
              nome: response.data.pessoa.endereco.cidade,
              uf: response.data.pessoa.endereco.uf,
              codigo_ibge: response.data.pessoa.endereco.codigo_ibge,
            };
          }
        }

        $scope.updateValorLiquido();
      });
  }

  $scope.refreshMunicipios = function(search) {
    $http.get('/api/municipios?q=' + search)
      .then(function(response) {
        vm.municipios = response.data;
      });
  }

  $scope.refreshMunicipiosTomador = function(search) {
    $http.get('/api/municipios?q=' + search)
      .then(function(response) {
        vm.municipios_tomador = response.data;
      });
  }

  $scope.updateValorLiquido = function() {
    var valor_liquido = vm.nfse.valor_servicos;
    if (vm.nfse.valor_pis) valor_liquido -= vm.nfse.valor_pis;
    if (vm.nfse.valor_cofins) valor_liquido -= vm.nfse.valor_cofins;
    if (vm.nfse.valor_inss) valor_liquido -= vm.nfse.valor_inss;
    if (vm.nfse.valor_ir) valor_liquido -= vm.nfse.valor_ir;
    if (vm.nfse.valor_csll) valor_liquido -= vm.nfse.valor_csll;
    if (vm.nfse.outras_retencoes) valor_liquido -= vm.nfse.outras_retencoes;
    if (vm.nfse.desconto_incondicionado) valor_liquido -= vm.nfse.desconto_incondicionado;
    if (vm.nfse.desconto_condicionado) valor_liquido -= vm.nfse.desconto_condicionado;
    vm.nfse.valor_liquido = valor_liquido;
  }

  $scope.buscarCEP = function() {
    var cep = vm.nfse.cep_tomador;
    if (!cep) {
      return false;
    }
    Cep.search(cep, true)
      .then(function(response) {
        var dados = response.data;
        if (dados.tipo == 1 || dados.tipo == 2) {
          if (dados.tipo == 1) {
            vm.nfse.endereco_tomador = dados.logradouro;
            vm.nfse.bairro_tomador = dados.bairro;
          }
          if (dados.codigo_ibge.length > 0) {
            vm.nfse.cidade_tomador = {
              codigo_ibge: dados.codigo_ibge,
              nome: dados.cidade.toUpperCase(),
              uf: dados.uf,
            };
          } else {
            toastr.error('Código IBGE da cidade não encontrado.', 'Cidade desconhecida');
          }
        } else {
          toastr.error('Verifique se o CEP foi digitado corretamente.', 'CEP não encontrado');
        }
      }, function() {
        toastr.error('Verifique se o CEP foi digitado corretamente.', 'CEP não encontrado');
      });
  }

  $scope.imprimirNfse = function() {
    var modalInstance = $uibModal.open({
      templateUrl: '/views/nfse/_imprimir.html',
      controller: 'ImprimirNfseCtrl',
      resolve: {
        cnpj: function() { return vm.nfse.cnpj; },
        codigoVerificacao: function() { return vm.nfse.codigo_verificacao; },
        numero: function() { return vm.nfse.numero; },
      }
    });
  }

  $scope.emitirNFSe = function() {
    vm.carregando = true;
    $http.post('/api/nfse', vm.nfse)
      .then(function(response) {
        vm.nfseGerada = true;
        vm.nfse.numero = response.data.numero;
        vm.nfse.codigo_verificacao = response.data.codigoVerificacao;
        vm.nfse.cnpj = response.data.cnpj;
        $scope.imprimirNfse();
      })
      .catch(function(response) {
        vm.carregando = false;
        angular.forEach(response.data.erros, function(erro) {
          var msg = erro.mensagem;
          if (erro.codigo) msg = erro.codigo + ' - ' + erro.mensagem;
          toastr.error(msg, 'Ocorreu um erro:');
        });
      });
  }

  getConfiguacoes();
}]);