app.controller('IntervencaoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'intervencao', '$uibModalInstance', function($scope, $state, $stateParams, toastr, intervencao, $uibModalInstance) {
	$scope.intervencao = angular.copy(intervencao, {});

	$scope.popupDataAbertura = {
		opened: false
	};

	$scope.popupDataFechamento = {
		opened: false
	};

	$scope.openDataAbertura = function() {
		$scope.popupDataAbertura.opened = true;
	}

	$scope.openDataFechamento = function() {
		$scope.popupDataFechamento.opened = true;
	}

    $scope.save = function() {
        $uibModalInstance.close($scope.intervencao);
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    }

    $scope.atualizarDataAbertura = function() {
    	if ($scope.intervencao.status.id == 3) {
    		var hoje = new Date();
    		$scope.intervencao.encaminhado_para_id = null;
    		$scope.intervencao.data_fechamento = Date.parse(hoje.toJSON());
    	}
    }

	$scope.$on('modal.closing', function(event, result, isClosing) {
		if (!isClosing && result != 'cancel') { 
			event.preventDefault();
			return false;
		}
	});
}]);