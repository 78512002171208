app.controller('ErroEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Erro', 'Produto', function($scope, $state, $stateParams, toastr, Erro, Produto) {
	var vm = this;
	vm.erroId = $stateParams.erroId;

	getErro();
	getProdutos();

	function getErro() {
		Erro.getErro(vm.erroId)
			.success(function(response) {
				vm.erro = response;
			});
	}

	function getProdutos() {
		Produto.getProdutos()
			.success(function(response) {
				vm.produtos = response;
			});
	}

	$scope.updateErro = function() {
		Erro.updateErro(vm.erro)
			.then(function() {
				toastr.success('Erro alterado com sucesso.');
				$state.go('dashboard.erros.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.erroForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}

	$scope.addAnexo = function() {
		vm.erro.anexos.push({
			file: '',
			enviando: false
		});
	}

	$scope.deleteAnexo = function(index) {
		if (vm.erro.anexos[index].id) {
			vm.erro.anexos[index]._destroy = true;
		} else {
			vm.erro.anexos.splice(index, 1);
		}
	}

	$scope.uploadAnexo = function(index) {
		var arquivo = vm.erro.anexos[index].file;
		vm.erro.anexos[index].enviando = true;
		Erro.enviarArquivo(vm.erro.id, arquivo)
			.then(function(response) {
				vm.erro.anexos[index] = response.data;
			})
			.catch(function(response) {
				toastr.error('Erro ao enviar arquivo.');
			});
	}

	$scope.addProduto = function() {
		vm.erro.produtos_ids.push(vm.produto.id);
		vm.produto = null;
	}

	$scope.deleteProduto = function(index) {
		vm.erro.produtos_ids.splice(index, 1);
	}

	$scope.produtosDisponiveis = function(produtos) {
		var produtosDisponiveis = [];

		angular.forEach(produtos, function(produto) {
			if (!vm.erro.produtos_ids ||
				(vm.erro.produtos_ids.indexOf(produto.id) < 0 &&
				vm.erro.produtos_ids.indexOf('' + produto.id + '') < 0)) {
				produtosDisponiveis.push(produto);
			}
		});

		return produtosDisponiveis;
	}

	$scope.descricaoProduto = function(produtoId, produtos) {
		if (!produtos) return false;
		for (var i = 0; i < produtos.length; i++) {
			if (produtos[i].id == produtoId) {
				return produtos[i].nome_comercial;
			}
		}
		return null;
	}
}]);