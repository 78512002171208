app.factory('Atendimento', ['$http', function($http){
	return {
		getAtendimentos: function(pageNumber, params, sortType, sortReverse) {
			params.page = pageNumber;
			params.sort = sortType;
			params.dir = (sortReverse ? 'desc' : 'asc');
			return $http({
				url: '/api/atendimentos',
				method: 'GET', 
				params: params
			});
		},
		getAtendimento: function(id) {
			return $http.get('/api/atendimentos/' + id);	
		},
		getAtendimentosPendentes: function(userId, pessoaId, produtoId) {
			var params = {
				'user_id': userId,
				'pessoa_id': pessoaId,
				'produto_id': produtoId
			};
			return $http({
				url: '/api/atendimentos/pendentes',
				method: 'GET', 
				params: params
			});
		},
		enviarArquivo: function(atendimentoId, solicitacaoId, arquivo) {
			var fd = new FormData();
			fd.append('arquivo', arquivo);
			return $http.post('/api/atendimentos/' + atendimentoId + '/' + (solicitacaoId ? solicitacaoId : 0) + '/upload', fd, {
				transformRequest: angular.identity,
				headers: {'Content-Type': undefined}
			});
		},
		insertAtendimento: function (atendimentoData) {
			return $http.post('/api/atendimentos', atendimentoData);
		},
		updateAtendimento: function (atendimentoData) {
			return $http.put('/api/atendimentos/' + atendimentoData.id, atendimentoData);
		},
		deleteAtendimento: function(id) {
			return $http.delete('/api/atendimentos/' + id);
		},
		duplicateAtendimento: function(id, quantity) {
			return $http.post('/api/atendimentos/' + id + '/duplicate/' + quantity);
		}
	};
}])