app.controller('BancoListCtrl', ['$scope', 'Banco', 'toastr', 'SweetAlert', '$state', function($scope, Banco, toastr, SweetAlert, $state) {
	$scope.bancos = [];
	$scope.bancosPerPage = 10;

	$scope.refreshbancos = function() {
		Banco.getBancos()
			.then(function(response) {
				$scope.bancos = response.data;
			});
	}

	$scope.refreshbancos();

	$scope.deleteBanco = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção deste Banco não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				Banco.deleteBanco(id)
					.then(function() {
						toastr.success('Banco removido com sucesso.');
						$scope.refreshbancos();
					})
					.catch(function() {
						toastr.error('Não foi possível remover este Banco porque ele está vinculada em outros registros.');
					});
			}
		});
	}
}]);