app.controller('ContratoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Contrato', 'Conta', 'CentroCusto', 'Banco', 'Produto', 'Pessoa', '$uibModal', 'ModuloProduto', 'Funcao', 'SweetAlert', '$filter', '$http', 'FormaPagamento', function($scope, $state, $stateParams, toastr, Contrato, Conta, CentroCusto, Banco, Produto, Pessoa, $uibModal, ModuloProduto, Funcao, SweetAlert, $filter, $http, FormaPagamento) {
	var vm = this;
	vm.contratoId = $stateParams.contratoId;
	vm.editingProduto = {
		tipo_cobranca: 'M',
		recorrente: 'N'
	};

	getProdutos();
	getContrato();
	getModulosProdutos();
	getFuncoes();
	getContas();
	getBancos();
	getCentrosCusto();
	getFormasPagamento();

	vm.servicos = [];
	$http.get('/servicos.json')
		.then(function(response) {
			vm.servicos = response.data;
		});

	function getProdutos(){
		Produto.getProdutos()
			.then(function(response) {
				vm.produtos = response.data;
			});
	}

	function getContrato() {
		Contrato.getContrato(vm.contratoId)
			.success(function(response) {
				vm.contrato = response;
			});
	}

	function getModulosProdutos(){
		ModuloProduto.getModulosProdutos()
			.then(function(response) {
				vm.modulos_produtos = response.data;
			});
	}

	function getFuncoes() {
		Funcao.getFuncoes()
			.then(function(response) {
				vm.funcoes = response.data;
			});
	}

	function getContas(){
		Conta.getContas()
			.then(function(response) {
				vm.contas = response.data;
			});
	}

	function getBancos(){
		Banco.getBancos()
			.then(function(response) {
				vm.bancos = response.data;
			});
	}

	function getCentrosCusto(){
		CentroCusto.getCentrosCusto()
			.then(function(response) {
				vm.centros_custo = response.data;
			});
	}

	function getFormasPagamento(){
		FormaPagamento.getFormasPagamento()
			.then(function(response) {
				vm.formas_pagamento = response.data;
			});
	}

	$scope.updateContrato = function() {
		Contrato.updateContrato(vm.contrato)
			.then(function() {
				toastr.success('Contrato alterado com sucesso.');
				$state.go('dashboard.contratos.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.contratoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}

	vm.popupInicioVigencia = {
		opened: false
	};

	vm.popupFimVigencia = {
		opened: false
	};

	vm.popupDataPagamento = {
		opened: false
	};

	vm.popupDataPrimeiraCobranca = {
		opened: false
	};

	vm.popupDataVenda = {
		opened: false
	};

	$scope.openInicioVigencia = function() {
		vm.popupInicioVigencia.opened = true;
	}

	$scope.openFimVigencia = function() {
		vm.popupFimVigencia.opened = true;
	}

	$scope.openDataPrimeiraCobranca = function() {
		vm.popupDataPrimeiraCobranca.opened = true;
	}

	$scope.openDataVenda = function() {
		vm.popupDataVenda.opened = true;
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social')
			.then(function(response) {
				vm.pessoas = response.data.items;
			});
	}

	$scope.addModuloProduto = function() {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/contratos/_modulo.html',
			controller: 'ContratoModuloProdutoEditCtrl',
			scope: $scope,
			resolve: {
				modulo_produto: {
					funcoes_ids: []
				}
			}
		});

		modalInstance.result.then(function(modulo_produto) {
			vm.contrato.modulos_produtos.push(modulo_produto);
		});
	}

	$scope.editModuloProduto = function(index) {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/contratos/_modulo.html',
			controller: 'ContratoModuloProdutoEditCtrl',
			scope: $scope,
			resolve: {
				modulo_produto: vm.contrato.modulos_produtos[index]
			}
		});

		modalInstance.result.then(function(modulo_produto) {
			vm.contrato.modulos_produtos[index] = modulo_produto;
		});
	}

	$scope.deleteModuloProduto = function(index) {
		vm.contrato.modulos_produtos.splice(index, 1);
	}

	$scope.modulosDisponiveis = function(moduloAtual) {
		var modulosDisponiveis = [];
		var modulosUsados = [];

		angular.forEach(vm.contrato.modulos_produtos, function(modulo_produto) {
			modulosUsados.push(modulo_produto.modulo.id);
		})

		angular.forEach(vm.modulos_produtos, function(modulo_produto) {
			if (modulosUsados.indexOf(modulo_produto.id) < 0 ||
				(moduloAtual.modulo && moduloAtual.modulo.id == modulo_produto.id)) {
				modulosDisponiveis.push(modulo_produto);
			}
		});

		return modulosDisponiveis;
	}

	$scope.modulosDisponiveis = function(moduloAtual) {
		var modulosDisponiveis = [];
		var modulosUsados = [];

		angular.forEach(vm.contrato.modulos_produtos, function(modulo_produto) {
			modulosUsados.push(modulo_produto.modulo.id);
		})

		angular.forEach(vm.modulos_produtos, function(modulo_produto) {
			if (modulosUsados.indexOf(modulo_produto.id) < 0 ||
				(moduloAtual.modulo && moduloAtual.modulo.id == modulo_produto.id)) {
				modulosDisponiveis.push(modulo_produto);
			}
		});

		return modulosDisponiveis;
	}

	$scope.addContratoValor = function() {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/contratos/_valor.html',
			controller: 'ContratoValorEditCtrl',
			scope: $scope,
			resolve: {
				valor: {
					data: new Date(),
					valor: 0
				}
			}
		});

		modalInstance.result.then(function(valor) {
			vm.contrato.valores.push(valor);
		});
	}

	$scope.editContratoValor = function(index) {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/contratos/_valor.html',
			controller: 'ContratoValorEditCtrl',
			scope: $scope,
			resolve: {
				valor: vm.contrato.valores[index]
			}
		});

		modalInstance.result.then(function(valor) {
			vm.contrato.valores[index] = valor;
		});
	}

	$scope.deleteContratoValor = function(index) {
		vm.contrato.valores.splice(index, 1);
	}

	$scope.$watch('vm.contrato.fim_vigencia', function(newVal) {
		if (!vm.contrato) {
			return false;
		}
		$scope.atualizarStatus();
	});

	$scope.atualizarStatus = function() {
		var fimVigencia = (new Date(vm.contrato.fim_vigencia)).getTime();
		var hoje = (new Date()).getTime();
		vm.contrato.status = vm.contrato.renova_automaticamente == 'N' && fimVigencia < hoje ? 'I' : 'A';
	}

	$scope.editProduto = function(index) {
		vm.editingProduto = angular.copy(vm.contrato.produtos[index], {});
		vm.editingProdutoIndex = index;
	}

	$scope.saveProduto = function() {
		if (vm.editingProdutoIndex >= 0) {
			vm.contrato.produtos[vm.editingProdutoIndex] = vm.editingProduto;
			vm.editingProdutoIndex = null;
		} else {
			var dt = new Date();
			var timestamp = dt.getTime();
			vm.editingProduto.temp_id = '__' + timestamp + '__';
			vm.contrato.produtos.push(vm.editingProduto);
		}
		vm.editingProduto = {
			tipo_cobranca: 'M',
			recorrente: 'N'
		};
		vm.produtoForm.$setPristine();
	}

	$scope.deleteProduto = function(index) {
		if (vm.contrato.produtos[index].id) {
			vm.contrato.produtos[index]._destroy = true;
		} else {
			vm.contrato.produtos.splice(index, 1);
		}
	}

	$scope.$watch('vm.contrato.renova_automaticamente', function(newVal) {
		if (!vm.contrato) {
			return false;
		}
		$scope.atualizarStatus();
	});

	$scope.refreshProgramadores = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social', 2)
			.then(function(response) {
				vm.programadores = response.data.items;
			});
	}

	$scope.refreshVendedores = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social', 11)
			.then(function(response) {
				vm.vendedores = response.data.items;
			});
	}

	$scope.importarModulos = function() {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/contratos/_picker.html',
			controller: 'ContratoPickerCtrl',
			scope: $scope
		});

		modalInstance.result.then(function(contratoId) {
			if (contratoId) {
				Contrato.getContrato(contratoId)
					.success(function(response) {
						for (var i = 0; i < response.modulos_produtos.length; i++) {
							vm.contrato.modulos_produtos.push(response.modulos_produtos[i]);
						}
					});
			}
		});
	}

	$scope.gerarParcelas = function(historico) {
		SweetAlert.swal({
			title: '',
			text: 'Deseja gerar Contas a Receber deste Contrato:',
			type: 'info',
			cancelButtonText: 'Não',
			confirmButtonColor: "#46B999",
			confirmButtonText: "Sim",
			showCancelButton: true,
			closeOnConfirm: false,
			closeOnCancel: false
		}, function(isConfirm) {
			SweetAlert.swal({
				title: '',
				text: 'Numero de Parcelas a ser gerada?',
				type: 'input',
				cancelButtonText: 'Cancelar',
				confirmButtonColor: "#46B999",
				confirmButtonText: "Gerar",
				showCancelButton: true,
				closeOnConfirm: false,
				inputValue: 1,
				inputPlaceholder: 'Digite a quantidade',
				showLoaderOnConfirm: true
			}, function(inputValue) {
				var quantity = parseInt(inputValue);

				if (isNaN(quantity) || quantity < 1) {
					SweetAlert.showInputError('Você precisa digitar um valor.');
					return false;
				}

				var produtos = $filter('filter')(vm.contrato.produtos, {produto: {id: historico.produto_id}});
				var produto = produtos[0];
				var receita = produto.receita;
				var contaId = produto.conta.id;
				var centroCustoId = produto.centro_custo.id;

				if (!historico.data_primeira_cobranca) {
					SweetAlert.showInputError('Data da primeira cobrança não foi preenchida.');
					return false;
				}

				if (!produto.dia_vencimento) {
					SweetAlert.showInputError('Dia do vencimento não foi preenchido.');
					return false;
				}

				Contrato.gerarParcelas(
						vm.contratoId,
						quantity,
						historico.valor,
						historico.data_primeira_cobranca,
						produto.dia_vencimento,
						receita,
						contaId,
						centroCustoId,
						vm.contrato.banco_id,
						produto
					)
					.then(function(response) {
						SweetAlert.close();
						historico.num_parcelas = quantity;
						historico.data_envio_financeiro = response.data.data_envio_financeiro;
						toastr.success('Parcelas geradas com sucesso.');
					});
			});
		});
	}
}]);