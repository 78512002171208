app.controller('AvaliacaoReceitasDespesasAnualCtrl', ['$scope', 'Banco', 'Conta', 'CentroCusto', 'Pessoa', 'Auth', function ($scope, Banco, Conta, CentroCusto, Pessoa, Auth) {
	var anoAtual = (new Date()).getFullYear();

	$scope.anos = [anoAtual];
	for (var a = anoAtual; a >= anoAtual-10; a--) {
		$scope.anos.push(a);
	}

	$scope.filtros = {
		ano: anoAtual
	};

	getBancos();
	getContas();
	getCentrosCusto();

	function getContas(){
		Conta.getContas()
			.then(function(response) {
				$scope.contas = response.data;
			});
	}

	function getBancos(){
		Banco.getBancos()
			.then(function(response) {
				$scope.bancos = response.data;
			});
	}

	function getCentrosCusto(){
		CentroCusto.getCentrosCusto()
			.then(function(response) {
				$scope.centros_custo = response.data;
			});
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social')
			.then(function(response) {
				$scope.pessoas = response.data.items;
			});
	}

	$scope.getUrl = function() {
		var token = Auth.getToken();
		return '/api/relatorios/avaliacao-receitas-despesas-anual?token=' + token + '&' + $.param($scope.filtros);
	}
}]);