app.factory('Banco', ['$http', function($http){
	return {
		getBancos: function() {
			return $http.get('/api/banco');
		},
		getBanco: function(id) {
			return $http.get('/api/banco/' + id);
		},
		insertBanco: function (bancoData) {
			return $http.post('/api/banco', bancoData);
		},
		updateBanco: function (bancoData) {
			return $http.put('/api/banco/' + bancoData.id, bancoData);
		},
		deleteBanco: function(id) {
			return $http.delete('/api/banco/' + id);
		}
	};
}])