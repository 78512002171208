app.controller('NavbarCtrl', ['$scope', 'Auth', 'toastr', '$state', '$rootScope', function ($scope, Auth, toastr, $state, $rootScope) {
	$scope.currentUser = Auth.getCurrentUser();

	$rootScope.$on('currentUserChanged', function() {
		$scope.currentUser = Auth.getCurrentUser();		
	})

	$scope.logout = function() {
		Auth.logout();
		toastr.success('Sessão finalizada com sucesso.');
		$state.go('login');
	}
}]);