app.directive('loginDialog', ['AUTH_EVENTS', '$uibModal', 'Auth', '$state', function (AUTH_EVENTS, $uibModal, Auth, $state) {
	return {
		restrict: 'A',
		link: function (scope) {
			var modalIsOpen = false;

			var showDialog = function() {
				if (!modalIsOpen) {				
					var modalInstance = $uibModal.open({
						templateUrl: '/views/login-dialog.html',
						controller: 'LoginDialogCtrl',
						windowClass: 'login-dialog'
					});

					modalIsOpen = true;

					modalInstance.result.then(function(token) {
						Auth.saveToken(token);
						modalIsOpen = false;
					}, function () {
						Auth.logout();
						modalIsOpen = false;
						$state.go('login');
					});
				}
			};

			scope.$on(AUTH_EVENTS.notAuthenticated, showDialog);
			scope.$on(AUTH_EVENTS.sessionTimeout, showDialog);
		}
	};
}])