app.controller('MailingEditCtrl', ['$scope', '$location', '$stateParams', '$http', 'toastr', 'Pessoa', 'Setor', 'Tipo', 'Origem', 'RamoAtuacao', '$state', '$uibModal', 'Cep', 'TipoRelacionamento', 'TipoContato', 'Motivo', 'Produto', 'Auth', 'SweetAlert', function($scope, $location, $stateParams, $http, toastr, Pessoa, Setor, Tipo, Origem, RamoAtuacao, $state, $uibModal, Cep, TipoRelacionamento, TipoContato, Motivo, Produto, Auth, SweetAlert) {
	var vm = this;
	vm.pessoaId = $stateParams.pessoaId;
	vm.editingContato = {
		ativo: 'S'
	};
	vm.errorMessage = {};

	getSetores();
	getOrigens();
	getRamosAtuacao();
	getPessoa();
	getTiposRelacionamento();
	getTiposContato();
	getMotivos();
	getProdutos();

	function getPessoa() {
		Pessoa.getPessoa(vm.pessoaId)
			.success(function(response) {
				vm.pessoa = response;
			});
	}

	function getSetores(){
		Setor.getSetores()
			.then(function(response) {
				vm.setores = response.data;
			});
	}

	function getRamosAtuacao(){
		RamoAtuacao.getRamosAtuacao()
			.then(function(response) {
				vm.ramos_atuacao = response.data;
			});
	}

	function getOrigens(){
		Origem.getOrigens()
			.then(function(response) {
				vm.origens = response.data;
			});
	}

	function getTiposRelacionamento() {
		TipoRelacionamento.getTiposRelacionamento()
			.then(function(response) {
				$scope.tipos = response.data;
			})
	}

	function getTiposContato(){
		TipoContato.getTiposContato()
			.then(function(response) {
				$scope.tipos_contato = response.data;
			});
	}

	function getProdutos(){
		Produto.getProdutos()
			.then(function(response) {
				$scope.produtos = response.data;
			});
	}

	function getMotivos(){
		Motivo.getMotivos()
			.then(function(response) {
				$scope.motivos = response.data;
			});
	}

	$scope.updatePessoa = function(redirect) {
		Pessoa.updatePessoa(vm.pessoa)
			.then(function() {
				vm.pessoaForm.$setPristine();
				vm.enderecoForm.$setPristine();
				toastr.success('Pessoa alterada com sucesso.');
				if (redirect) {
					$state.go('dashboard.mailing.list');
				} else {
					getPessoa();
				}
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.pessoaForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}

	$scope.editContato = function(index) {
		vm.editingContato = angular.copy(vm.pessoa.contatos[index], {});
		vm.editingContatoIndex = index;
	}

	$scope.saveContato = function() {
		if (vm.editingContatoIndex >= 0) {
			vm.pessoa.contatos[vm.editingContatoIndex] = vm.editingContato;
			vm.editingContatoIndex = null;
		} else {
			var dt = new Date();
			var timestamp = dt.getTime();
			vm.editingContato.temp_id = '__' + timestamp + '__';
			vm.pessoa.contatos.push(vm.editingContato);
		}
		vm.editingContato = {
			ativo: 'S'
		};
		vm.contatoForm.$setPristine();
	}

	$scope.deleteContato = function(index) {
		if (vm.pessoa.contatos[index].id) {
			Pessoa.deleteContato(vm.pessoaId, vm.pessoa.contatos[index].id)
				.then(function() {
					vm.pessoa.contatos[index]._destroy = true;
				})
				.catch(function(response) {
					if (response.data.error == 'record_being_used') {
						SweetAlert.swal({
							title: 'Não pode apagar',
							text: 'Esse Contato não pode ser excluído, pois esta em uso no sistema, deseja torná-lo Inativo?',
							type: 'warning',
							cancelButtonText: 'Não',
							confirmButtonColor: "#46B999",
							confirmButtonText: "Sim",
							showCancelButton: true,
							closeOnConfirm: true,
							closeOnCancel: true
						}, function(isConfirm) {
							if (isConfirm) {
								vm.pessoa.contatos[index].ativo = 'N';
							}
						});
					}
				});
		} else {
			vm.pessoa.contatos.splice(index, 1);
		}
	}

	$scope.boolToStr = function(value) {
		return value ? 'S' : 'N';
	}

	$scope.$watch('vm.pessoa.cnpj', function() {
		if (vm.pessoaForm) {		
			vm.pessoaForm['cnpj'].$setValidity('server', true);
			vm.errorMessage['cnpj'] = null;
		}
	});

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social')
			.then(function(response) {
				var items = response.data.items;
				items.push({id: null, razao_social: '-- Nenhum --'});
				vm.pessoas = items;
			});
	}

	$scope.addHistoricoContato = function() {
		var currentUser = Auth.getCurrentUser();

		var modalInstance = $uibModal.open({
			templateUrl: '/views/mailing/_historico_contato.html',
			controller: 'HistoricoContatoEditCtrl',
			scope: $scope,
			resolve: {
				historico_contato: {
					data: new Date(),
					user: currentUser,
					status: 'A',
					produtos_ids: []
				},
				vm: vm
			}
		});

		modalInstance.result.then(function(historico_contato) {
			var dt = new Date();
			var timestamp = dt.getTime();
			historico_contato.temp_id = '__' + timestamp + '__';

			vm.pessoa.historico_contatos.push(historico_contato);
		});
	}

	$scope.editHistoricoContato = function(index) {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/mailing/_historico_contato.html',
			controller: 'HistoricoContatoEditCtrl',
			scope: $scope,
			resolve: {
				historico_contato: vm.pessoa.historico_contatos[index],
				vm: vm
			}
		})

		modalInstance.result.then(function(historico_contato) {
			vm.pessoa.historico_contatos[index] = historico_contato;
		});
	}

	$scope.deleteHistoricoContato = function(index) {
		if (vm.pessoa.historico_contatos[index].id) {
			vm.pessoa.historico_contatos[index]._destroy = true;
		} else {
			vm.pessoa.historico_contatos.splice(index, 1);
		}
	}

	$scope.buscarCep = function() {
		var cep = vm.pessoa.endereco.cep;
		
		if (!cep) {
			return false;
		};

		Cep.search(cep)
			.then(function(response) {
				var dados = response.data;
				if (dados.tipo == 1) {
					vm.pessoa.endereco.uf = dados.uf;
					vm.pessoa.endereco.cidade = dados.cidade;
					vm.pessoa.endereco.logradouro = dados.logradouro;
					vm.pessoa.endereco.bairro = dados.bairro;
				} else if (dados.tipo == 2) {
					vm.pessoa.endereco.uf = dados.uf;
					vm.pessoa.endereco.cidade = dados.cidade;
				} else {
					toastr.error('Verifique se o CEP foi digitado corretamente.', 'CEP não encontrado');
				}
			}, function() {
				toastr.error('Verifique se o CEP foi digitado corretamente.', 'CEP não encontrado');
			});
	}

	$scope.corrigeSite = function() {		
		if (!vm.pessoa.site || vm.pessoa.site.length == 0) {
			return false;
		}

		if (vm.pessoa.site.indexOf('http://') < 0 && vm.pessoa.site.indexOf('https://') < 0) {
			vm.pessoa.site = 'http://' + vm.pessoa.site;
		}
	}

	$scope.addAnexo = function() {
		vm.pessoa.anexos.push({
			file: '',
			enviando: false
		});
	}

	$scope.deleteAnexo = function(index) {
		if (vm.pessoa.anexos[index].id) {
			vm.pessoa.anexos[index]._destroy = true;
		} else {
			vm.pessoa.anexos.splice(index, 1);
		}
	}

	$scope.uploadAnexo = function(index) {
		var arquivo = vm.pessoa.anexos[index].file;
		vm.pessoa.anexos[index].enviando = true;
		Pessoa.enviarArquivo(vm.pessoa.id, arquivo)
			.then(function(response) {
				vm.pessoa.anexos[index] = response.data;
			})
			.catch(function(response) {
				toastr.error('Erro ao enviar arquivo.');
			});
	}
}]);