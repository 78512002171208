app.factory('Conta', ['$http', function($http){
	return {
		getContas: function(sortType, sortReverse) {
			if (!sortType) {
				sortType = 'contas.nome';
			}
			return $http({
				url: '/api/contas',
				method: 'GET', 
				params: {
					sort: sortType,
					dir: sortReverse ? 'desc' : 'asc'
				}
			});
		},
		getConta: function(id) {
			return $http.get('/api/contas/' + id);	
		},
		insertConta: function (contaData) {
			return $http.post('/api/contas', contaData);
		},
		updateConta: function (contaData) {
			return $http.put('/api/contas/' + contaData.id, contaData);
		},
		deleteConta: function(id) {
			return $http.delete('/api/contas/' + id);
		}
	};
}])