app.controller('SettingsCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Settings', 'Cep', '$http', function($scope, $state, $stateParams, toastr, Settings, Cep, $http) {
	var vm = this;
	vm.settings = [];

  vm.naturezaOperacao = {
    '1': 'Tributação no município',
    '2': 'Tributação fora do município',
    '3': 'Isenção',
    '4': 'Imune',
    '5': 'Exigibilidade suspensa por decisão judicial',
    '6': 'Exigibilidade suspensa por procedimento administrativo'
  };

  vm.regimeEspecialTributacao = {
    '1': 'Microempresa Municipal',
    '2': 'Estimativa',
    '3': 'Sociedade de Profissionais',
    '4': 'Cooperativa',
    '5': 'MEI do Simples Nacional',
    '6': 'ME ou EPP do Simples Nacional'
  };

	getSettings();
	getServicos();

	function getServicos(){
	  $http.get('/servicos.json')
	    .then(function(response) {
	      vm.servicos = response.data;
	    });
	}

	function getSettings() {
		Settings.getSettings()
			.then(function(response) {
				vm.settings = response.data;
			});
	}

	  $scope.refreshMunicipios = function(search) {
	    $http.get('/api/municipios?q=' + search + '&uf=RS')
	      .then(function(response) {
	        vm.municipios = response.data;
	      });
	  }

	  $scope.refreshMunicipiosServico = function(search) {
	    $http.get('/api/municipios?q=' + search)
	      .then(function(response) {
	        vm.municipios_servico = response.data;
	      });
	  }

	$scope.buscarCep = function() {
		var cep = vm.settings.cep;
		
		if (!cep) {
			return false;
		};

		Cep.search(cep)
			.then(function(response) {
				var dados = response.data;
				if (dados.tipo == 1) {
					vm.settings.uf = dados.uf;
					vm.settings.cidade = dados.cidade;
					vm.settings.logradouro = dados.logradouro;
					vm.settings.bairro = dados.bairro;
				} else if (dados.tipo == 2) {
					vm.settings.uf = dados.uf;
					vm.settings.cidade = dados.cidade;
				} else {
					toastr.error('Verifique se o CEP foi digitado corretamente.', 'CEP não encontrado');
				}
			}, function() {
				toastr.error('Verifique se o CEP foi digitado corretamente.', 'CEP não encontrado');
			});
	}

	$scope.updateSettings = function() {
		Settings.updateSettings(vm.settings)
			.then(function() {
				toastr.success('Configurações alteradas com sucesso.');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.settingsForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);