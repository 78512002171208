app.controller('RelatorioContratosCtrl', ['$scope', 'Produto', 'Pessoa', 'Auth', function($scope, Produto, Pessoa, Auth) {
	$scope.filtros = {
		vendedor: {}
	};

	$scope.popupDataInicial = {
		opened: false
	};

	$scope.popupDataFinal = {
		opened: false
	};

	getProdutos();

	function getProdutos(){
		Produto.getProdutos()
			.then(function(response) {
				$scope.produtos = response.data;
			});
	}	

	$scope.refreshVendedores = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social', 11)
			.then(function(response) {
				$scope.vendedores = response.data.items;
			});
	}

	$scope.openDataInicial = function() {
		$scope.popupDataInicial.opened = true;
	}

	$scope.openDataFinal = function() {
		$scope.popupDataFinal.opened = true;
	}

	$scope.getUrl = function() {
		var token = Auth.getToken();
		return '/api/relatorios/contratos?token=' + token + '&' + $.param($scope.filtros);
	}
}]);